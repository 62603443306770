import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Menu from './menu';
import Conocenos from './conocenos';
import Servicios from './servicios';
import Catalogo from './catalogo';
import Form from './contacto';
import Footer from './footer';
import TypingText from './TypingText'; // Importa TypingText
import foto1 from './imagenes/tecnologias.png';

// Componentes de ejemplo para las otras rutas
function Home() {
  return (
    <div>
      <TypingText /> {/* Agrega TypingText al inicio de Home */}

      <div className='section-job'>
        <div className='section-job-container'>
          <h1 className='title1'>Desarrollo Full Code</h1>
          <p className='section-job-p'>En Keycapsoft, nos enorgullece ser una empresa de desarrollo de software comprometida con la excelencia y la responsabilidad ética. Trabajamos siempre con las últimas tecnologías, y nuestro enfoque se centra en desarrollar soluciones 100% en código, lo cual garantiza un mayor nivel de personalización, seguridad y rendimiento en comparación con plataformas prediseñadas.<br/><br/>

          El uso de código en lugar de estas soluciones prefabricadas nos permite crear aplicaciones adaptadas exactamente a las necesidades de cada cliente. A diferencia de otras tecnologias, que dependen de plantillas, plugins y funcionalidades prediseñadas, nuestras soluciones en código brindan mayor flexibilidad y escalabilidad. Esto es fundamental para clientes que buscan un rendimiento óptimo, mayor seguridad y la libertad de realizar ajustes únicos y específicos a sus sistemas.<br/><br/>

          Además, nuestro equipo sigue estrictamente los estándares más altos de calidad en cada etapa del proceso de desarrollo, asegurando que cada software cumpla con las expectativas de nuestros clientes y con los principios de ética y responsabilidad digital.<br/><br/>

          Este enfoque se conoce como *Desarrollo Full Code*, en el que construimos soluciones desde cero, optimizando cada línea de código y personalizando cada función. En Keycapsoft, creemos firmemente que el desarrollo en código es la mejor manera de garantizar la sostenibilidad y el éxito a largo plazo de nuestros productos.</p>
        </div>

        <div className='section-job-img1'>
          <img src={foto1} alt='Tecnológias utilizadas' className='foto1'/>
        </div>
      </div>
      
      <Form/>
      <Footer/>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div>
        <Menu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/conocenos" element={<Conocenos />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/catalogo" element={<Catalogo />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
