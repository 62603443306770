import './conocenos.css';
import { Link } from 'react-router-dom';
import logo from './imagenes/Keycapsoft.png';
import Footer from './footer';

function Conocenos(){
    return(
        <div>
          <div className='section-one'>
            <div className='section-one-container'>
              <div className='section-one-one'>
                <h1 className='title-one'>¿Quienes somos?</h1>
                <p className='historia'>
                  Keycapsoft es una empresa de desarrollo de software dedicada a ofrecer soluciones tecnológicas innovadoras y personalizadas para empresas de todos los tamaños. Su enfoque principal radica en el desarrollo de sitios web, aplicaciones que satisfacen las necesidades específicas de cada cliente. Además, ofrece servicios de gestión de software.
                </p>
              </div>
              <div className='section-one-two'>
                <h1 className='title-two'>Misión</h1>
                <p className='mision'>Nuestra misión en Keycapsoft es ofrecer soluciones tecnológicas innovadoras y personalizadas que potencien la eficiencia y el crecimiento de nuestros clientes. Nos comprometemos a brindar servicios de alta calidad, integridad y profesionalismo en el desarrollo de software, garantizando la satisfacción y el éxito de nuestros clientes a través de la excelencia en cada proyecto.</p>
              </div>
              <div className='section-one-two'>
                <h1 className='title-two'>Visión</h1>
                <p className='vision'>Ser líderes en la industria tecnológica, reconocidos por nuestra capacidad de adaptación, innovación y enfoque centrado en el cliente. Aspiramos a expandir nuestro alcance globalmente, ofreciendo soluciones de software que transformen y mejoren los negocios de nuestros clientes, estableciendo nuevos estándares de calidad y servicio en el sector.</p>
              </div>
            </div>

            <div className='figura-container'>
              
                <img src={logo} alt='imagen' className='imagen'/>
              
            </div>
        </div>

        <Footer/>
      </div>
    );
}

export default Conocenos;