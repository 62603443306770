import { Link } from 'react-router-dom';
import './servicios.css';
import Footer from './footer';
import Plan1 from './imagenes/plan1.png';
import Plan2 from './imagenes/plan2.png';

const Servicios = () => {
    const phoneNumber = '525561784431'; // Coloca aquí el número de WhatsApp en formato internacional

    const handleWhatsAppClick = (message) => {
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    return (
        <div className='section-principal-servicios'>
            <div className='servicios'>
                <div className='section-servicios'>
                    <div className='section-servicios-web'>
                        <h1 className='section-servicios-title'>Desarrollo de Sitios Web</h1>
                        <p className='text-description'>Nos especializamos en el desarrollo de sitios web personalizados que van más allá de las soluciones prefabricadas. Nos especializamos en crear sitios web robustos, seguros y escalables utilizando tecnologías de vanguardia. Desde páginas corporativas hasta plataformas de comercio electrónico, nuestro enfoque se centra en la calidad del código y la adaptabilidad a las necesidades específicas de cada cliente.</p>
                        <div className='card-container'>
                            <div className='card'>
                                <img src={Plan1} alt='Plan 1' className='img-plan1'/>
                                <h1 className='title-card'>Desde $1,499 / mes</h1>
                                <p className='text-description-card'>Incluye:</p>
                                <ul>
                                    <li className='text-description-card'>Desarrollo del sitio</li>
                                    <li className='text-description-card'>Dominio</li>
                                    <li className='text-description-card'>Hosting</li>
                                    <li className='text-description-card'>Gestión del sitio</li>
                                    <li className='text-description-card'>Soporte</li>
                                </ul>
                                <button onClick={() => handleWhatsAppClick('Estoy interesado en el plan de desarrollo de sitios web desde $1,499 / mes.')}>Más información</button>
                            </div>
                            <div className='card'>
                                <img src={Plan2} alt='Plan 2' className='img-plan2'/>
                                <h1 className='title-card'>Desde $2,999 / mes</h1>
                                <p className='text-description-card'>Incluye:</p>
                                <ul>
                                    <li className='text-description-card'>Desarrollo del sitio</li>
                                    <li className='text-description-card'>Implementación de framework</li>
                                    <li className='text-description-card'>Manejo de rutas</li>
                                    <li className='text-description-card'>Dominio</li>
                                    <li className='text-description-card'>Hosting</li>
                                    <li className='text-description-card'>Gestión del sitio</li>
                                    <li className='text-description-card'>Soporte</li>
                                </ul>
                                <button onClick={() => handleWhatsAppClick('Quiero más información sobre el plan de desarrollo de sitios web desde $2,999 / mes.')}>Más información</button>
                            </div>
                        </div>
                    </div>

                    <div className='section-servicios-apps'>
                        <h1 className='section-servicios-title'>Desarrollo de Apps</h1>
                        <p className='text-description'>Ofrecemos el desarrollo de aplicaciones que funcionan perfectamente en dispositivos iOS, Android, Windows. Creamos aplicaciones móviles que ofrecen una experiencia de usuario consistente y de alta calidad en todas las plataformas.</p>
                        <div className='card-container'>
                            <div className='card'>
                                <img src={Plan1} alt='Plan 1' className='img-plan1'/>
                                <p className='text-description-card'>Incluye:</p>
                                <ul>
                                    <li className='text-description-card'>Desarrollo de la App</li>
                                    <li className='text-description-card'>Diseño</li>
                                    <li className='text-description-card'>Parches de seguridad</li>
                                    <li className='text-description-card'>Soporte</li>
                                </ul>
                                <button onClick={() => handleWhatsAppClick('Estoy interesado en el desarrollo de aplicaciones móviles.')}>Más información</button>
                            </div>
                        </div>
                    </div>

                    <div className='section-servicios-gestion'>
                        <h1 className='section-servicios-title'>Gestión de Software</h1>
                        <p className='text-description'>Brindamos servicios integrales de gestión de software que abarcan desde la planificación y desarrollo hasta la implementación y el mantenimiento. Nuestro enfoque se centra en garantizar que el software cumpla con los objetivos de negocio, sea fácil de usar y esté alineado con las mejores prácticas de la industria.</p>
                        <div className='card-container'>
                            <div className='card'>
                                <img src={Plan1} alt='Plan 1' className='img-plan1'/>
                                <p className='text-description-card'>Incluye:</p>
                                <ul>
                                    <li className='text-description-card'>Manejo del sitio</li>
                                    <li className='text-description-card'>Manejo de rutas</li>
                                    <li className='text-description-card'>Rediseño del sitio</li>
                                    <li className='text-description-card'>Corrección de bugs</li>
                                    <li className='text-description-card'>Soporte</li>
                                </ul>
                                <button onClick={() => handleWhatsAppClick('Me gustaría más información sobre la gestión de software.')}>Más información</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Servicios;
