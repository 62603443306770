// TypingText.js
import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import "./TypingText.css";
import fondo from './imagenes/fondo.mp4';

// Registra el plugin de texto
gsap.registerPlugin(TextPlugin);

const TypingText = () => {
  const textRef = useRef(null);

  useEffect(() => {
    const timeline = gsap.timeline({ repeat: -1, repeatDelay: 1 });
    const texts = ["Keycapsoft", "Desarrollando el futuro"];

    texts.forEach((text) => {
      timeline.to(textRef.current, {
        text: text,
        duration: text.length * 0.1,
        ease: "none",
      });
      timeline.to(textRef.current, {
        text: "",
        duration: text.length * 0.05,
        ease: "none",
        delay: 1,
      });
    });
  }, []);

  return (
    <div className="fullscreen-section typing-text">
    {/* Video de fondo */}
    <video autoPlay muted loop>
      <source src={fondo} type="video/mp4" />
      Tu navegador no soporta videos en HTML5.
    </video>
    
    {/* Texto animado */}
    <h1 ref={textRef}></h1>
  </div>
  );
};

export default TypingText;
