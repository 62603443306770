import { Link } from "react-router-dom";
import './catalogo.css';
import Footer from './footer';

function Catalogo(){
    return(
        <div className="section-catalogo">
            <h1 className="section-catalogo-h1">Coming Soon!</h1>
            <Footer/>
        </div>
    );
}

export default Catalogo;