// src/components/Footer.js
import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h2>Keycapsoft</h2>
          <p>Desarrollando el futuro.</p>
        </div>
        <div className="footer-section links">
          <h2>Links</h2>
          <ul>
            <li><a href="#">Services</a></li>
            <li><a href="#contact">Contact</a></li>
            <li><a href="#privacy">Privacy Policy</a></li>
          </ul>
        </div>
        <div className="footer-section social">
          <h2>Siguenos</h2>
          <div className="social-links">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p className='derechos'>&copy; {new Date().getFullYear()} Keycapsoft. Todos los derechos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
