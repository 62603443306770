import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import './menu.css';
import logo from './imagenes/Keycapsoft.png';

function Menu() {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setIsOpen(false); // Cierra el menú cuando es mayor que 768px
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <nav className="navbar">
            <a href='/'><img src={logo} alt="Keycapsoft" className='logo'/></a>

            <div className={`nav_items ${isOpen ? 'open' : ''}`}>
                {location.pathname !== "/" && (
                    <Link to="/">Inicio</Link>
                )}
                <Link to="/conocenos">Conocenos</Link>
                <Link to="/servicios">Servicios</Link>
                <Link to="/catalogo">Catálogo</Link>
            </div>

            <div className={`nav_toggle ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </nav>
    );
}

export default Menu;
